._4q36la_container {
  border: 1px solid #2b2b2b;
  grid-template-rows: 17px 1fr;
  display: grid;
}

._4q36la_name {
  color: #fff;
  background-color: #2b2b2b;
  place-content: center;
  display: grid;
}

.tvl-va_name {
  text-align: right;
}

.tvl-va_nameInverted {
  text-align: left;
}

.tvl-va_valueNonEditable {
  color: #0000a0;
  opacity: .6;
  font-weight: 500;
}

.tvl-va_valueEditable {
  color: #0000a0;
  font-weight: 500;
}

.sPfGXq_container {
  color: inherit;
  background: none;
  border: none;
  border-bottom: 1px solid;
  width: 100%;
  font-weight: bold;
  display: block;
}

._9vXKcG_outerContainer {
  border: 1px solid #2b2b2b;
}

._9vXKcG_container {
  border-collapse: collapse;
  width: 100%;
}

._9vXKcG_container thead tr {
  border: none;
}

._9vXKcG_name {
  color: #fff;
  background-color: #2b2b2b;
  place-content: center;
  display: grid;
}

.CRl7Ia_container {
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  height: 17px;
}

.CRl7Ia_container:nth-child(2n) {
  background-color: #ebebdc;
}

.U1uE-q_container {
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
}

.U1uE-q_container:first-child {
  border-left: none;
}

.U1uE-q_container:last-child {
  border-right: none;
}

.U1uE-q_editableColor {
  color: #0000a0;
}

.U1uE-q_notEditableColor {
  color: #0000a099;
}

.SD0U5q_name {
  text-align: left;
}

.SD0U5q_value {
  color: #0000a0;
  text-align: right;
  font-weight: 500;
}

.SD0U5q_points {
  color: #0000a099;
  text-align: right;
}

.VOdfDa_container {
  color: #fff;
  font-weight: inherit;
  background-color: #2b2b2b;
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  height: 17px;
}

.VOdfDa_container:first-child {
  border-left: none;
}

.VOdfDa_container:last-child {
  border-right: none;
}
/*# sourceMappingURL=index.ca0798f4.css.map */
